<template>
  <section
    id="main-desc"
  >
    <base-left-photo
      :src="require('@/assets/main_desc-1.jpg')"
      subheading="스마트 팩토리 솔루션"
      html="스마트 팩토리 솔루션은 4차 산업혁명의 핵심 기술인 사물 인터넷(IoT), 빅데이터, 인공지능(AI), 클라우드 컴퓨팅 등을 활용하여 제조 공정을 자동화하고 최적화하는 솔루션입니다. 스마트 팩토리 솔루션은 제조 산업의 미래를 선도하는 핵심 기술이며, 기업의 경쟁력 강화에 필수적인 요소입니다."
      color="black--text"
    />
    <base-right-photo
      :src="require('@/assets/main_desc-2.jpg')"
      subheading="스마트 웨어하우스 솔루션"
      html="스마트 웨어하우스 솔루션은 사물 인터넷(IoT), 빅데이터, 인공지능(AI), 클라우드 컴퓨팅 등 4차 산업혁명의 핵심 기술을 활용하여 입출고 관리, 재고 관리, 물류 관리 등 웨어하우스 운영 전반을 자동화하고 최적화하는 솔루션입니다."
      color="black--text"
    />
    <base-left-photo
      :src="require('@/assets/main_desc-4.jpg')"
      subheading="시스템 통합"
      html="ERP, WCS, WMS, MES 등 각 시스템을 서로 연결하여 정보를 공유하고 연동하여 정확하고 신속한 의사결정 지원과 생산 공정 최적화, 생산 비용 절감을 이루어낼 수 있습니다."
      color="black--text"
    />
    <br>
    <br>
  </section>
</template>

<script>
  export default {
    name: 'SectionMainDesc',
  }
</script>
